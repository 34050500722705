import {
  Component,
  Input,
} from '@angular/core';

@Component({
  selector: 'page-menu',
  template: `
    <img [src]="icon">
    <p>{{ label }}</p>
    <small *ngIf="labelEN">( {{ labelEN }} )</small>
  `,
  styles: [`
    :host {
      text-align: center;
      cursor: pointer;
    }

    img {
      width: 60px;
    }

    p {
      margin-top: .85rem;
      color: #525252;
      font-size: 16px;
    }
  `]
})
export class PageMenuComponent {
  @Input()
  icon: string;

  @Input()
  label: string;

  @Input()
  labelEN: string;
}
