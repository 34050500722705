import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PageMenuComponent } from './page-menu.component';
import { PageOutletComponent } from './page-outlet.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [
    PageOutletComponent,
    PageMenuComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    PageOutletComponent,
    PageMenuComponent,
  ]
})
export class PageModule { }
